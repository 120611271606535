import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexHome from '../views/index/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexHome
  },
  {
    path: '/zulin',
    component: () => import('@/views/index/zulin.vue'),
  },
  {
    path: '/baoli',
    component: () => import('@/views/index/baoli.vue'),
  },
  {
    path: '/xiaoedai',
    component: () => import('@/views/index/xiaoedai.vue'),
  },
]

const router = new VueRouter({
  routes
})

export default router
